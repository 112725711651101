<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo" :to="{name:'home'}">
          <logo/>
          <h2 class="brand-text text-primary ml-1">
            USCAS
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          欢迎注册USCAS
        </b-card-title>
        <b-card-text class="mb-2">
          UtopiaXC's股票收集分析系统
        </b-card-text>

        <!-- form -->
        <b-form
            class="auth-register-form mt-2"
            @submit.prevent="register">
          <!-- username -->
          <b-form-group
              label="用户名"
              label-for="username">
            <b-form-input
                id="username"
                v-model="username"
                name="register-username"
                :state="username_individual"
                @input="checkUsername"
                placeholder="tony"/>
            <b-form-invalid-feedback
                :state="username_individual">
              用户名长度限制为2~16个字符，仅可包含字母数字与常见字符
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- password -->
          <b-form-group
              label="密码"
              label-for="password"
          >
            <b-input-group
                class="input-group-merge">
              <b-form-input
                  id="password"
                  :state="password_individual"
                  v-model="password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="register-password"
                  @input="checkPassword"
                  placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
                :state="password_individual">
              密码为必须包含大小写字母与数字的8~32位的组合强密码，且仅能包含字母数字与符号
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- password -->
          <b-form-group
              label="重复密码"
              label-for="password2"
          >
            <b-input-group
                class="input-group-merge">
              <b-form-input
                  id="password2"
                  v-model="password2"
                  :type="password2FieldType"
                  :state="password2_individual"
                  class="form-control-merge"
                  name="register-password2"
                  @input="checkPassword2"
                  placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                    :icon="password2ToggleIcon"
                    class="cursor-pointer"
                    @click="togglePassword2Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
                :state="password2_individual">
              两次密码输入不一致或密码强度不满足
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- email -->
          <b-form-group
              label="邮箱"
              label-for="email">
            <b-form-input
                id="email"
                v-model="email"
                name="register-email"
                @input="checkEmail"
                :state="email_individual"
                placeholder="example@example.com"/>
            <b-form-invalid-feedback
                :state="email_individual">
              请输入正确的邮箱地址
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              label="验证码"
              label-for="code">
            <b-input-group>
              <b-form-input
                  id="code"
                  v-model="code"
                  :state="code_individual"
                  @input="checkCode"
                  placeholder="123456"/>
              <b-input-group-append>
                <b-button
                    style="width: 150px"
                    :disabled="send_email_individual||!email_individual"
                    @click="sendEmail"
                    variant="outline-primary">
                  {{ send_email_button_text }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
                :state="code_individual">
              验证码格式错误
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- checkbox -->
          <b-form-group>
            <b-card-text>
              注册即代表您同意
              <b-link>隐私与使用政策</b-link>
            </b-card-text>
          </b-form-group>

          <!-- submit button -->
          <b-overlay
              :show="register_overlay"
              rounded="sm"
          >
            <b-button
                variant="primary"
                :disabled="!username_individual||!password_individual||!email_individual||!password2_individual||!code_individual"
                block
                type="submit">
              注册
            </b-button>
          </b-overlay>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>已有账号？ </span>
          <b-link :to="{name:'login'}">
            <span>前往登录</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Toast from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Logo,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormInvalidFeedback,
    BOverlay,
  },
  data() {
    return {
      passwordFieldType: 'password',
      password2FieldType: 'password',
      email: '',
      username: '',
      password: '',
      password2: '',
      status: '',
      code: '',
      username_individual: null,
      email_individual: null,
      send_email_individual: false,
      code_individual: null,
      password_individual: null,
      password2_individual: null,
      send_email_button_text: "发送验证码",
      register_overlay: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    checkUsername() {
      let pattern = /^[A-Za-z0-9^%&',;=?$\x22]{2,16}$/
      this.username_individual = pattern.test(this.username);
    },
    checkEmail() {
      let pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      this.email_individual = pattern.test(this.email);
    },
    checkCode() {
      let pattern = /^\d{6}$/
      this.code_individual = pattern.test(this.code);
    },
    checkPassword() {
      let pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/
      this.password_individual = pattern.test(this.password);
    },
    checkPassword2() {
      this.password2_individual = (this.password === this.password2) && this.password_individual
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    sendEmail() {
      this.send_email_individual = true
      let countDownNum = 60
      this.send_email_button_text = countDownNum + "秒后重新发送"
      let timer = setInterval(() => {
        countDownNum--;
        this.send_email_button_text = countDownNum + "秒后重新发送"
        if (countDownNum <= 0) {
          this.send_email_individual = false
          this.send_email_button_text = "重新发送"
          clearInterval(timer);
        }
      }, 1000);
      this.$http.post('/api/system/user/register/sendCode', {
        "email": this.email,
      })
          .then(
              response => {
                if (response.data.code !== 200) {
                  this.showToast("danger", "错误", "AlertCircleIcon", response.data.message, "top-center", 5000)
                } else {
                  this.showToast("success", "成功", "AlertCircleIcon", "邮件发送成功，有效期五分钟，请检查您的邮箱（可能被归类为垃圾邮件）", "top-center", 5000)
                }
              })
          .catch(error => {
            this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误，" + error, "top-center", 5000)
          })

    },
    register() {
      this.checkUsername()
      this.checkEmail()
      this.checkCode()
      this.checkPassword()
      if (!this.username_individual || !this.email_individual || !this.code_individual || !this.password_individual) {
        return
      }
      this.register_overlay = true
      this.$http.post('/api/system/user/register', {
        "user_name": this.username,
        "user_password": this.$md5(this.password),
        "code": this.code,
        "user_email": this.email
      })
          .then(
              response => {
                if (response.data.code !== 200) {
                  this.showToast("danger", "错误", "AlertCircleIcon", response.data.message, "top-center", 5000)
                  this.register_overlay = false
                } else {
                  this.showToast("success", "成功", "AlertCircleIcon", "注册成功，请前往登录", "top-center", 3000)
                  this.register_overlay = false
                  this.$router.push('login')
                }
              }).catch(error => {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误，" + error, "top-center", 5000)
        this.register_overlay = false
      })
    },
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    },
  }
}
</script>

<style lang="scss">
@import "src/@core/scss/vue/pages/page-auth";
</style>
